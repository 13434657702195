import React from "react"
import PostEntry from "./PostEntry"

function PostList({ posts, selectedCategory }) {
    return (
        <ol style={{ listStyle: `none`, margin: "0" }}>
            {posts.map((post, index) => {
                const title = post.frontmatter.title || post.frontmatter.slug

                if (
                    !selectedCategory ||
                    (!post.frontmatter.category && !selectedCategory) ||
                    post.frontmatter.category === selectedCategory
                )
                    return <PostEntry key={index} post={post} title={title} />
            })}
        </ol>
    )
}

export default PostList
