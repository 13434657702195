import { Col, Div, Row, Tag, Text } from "atomize"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import React from "react"

function PostEntry({ post, title }) {
    return (
        <Div key={post?.frontmatter?.slug} m={{ b: "3rem" }}>
            <Link to={`/${post.frontmatter.slug}`} itemProp="url">
                <motion.div
                    whileTap={{ scale: 0.98 }}
                    initial={{ y: 60 }}
                    animate={{ y: 0 }}
                    transition={{ ease: [0.04, 0.62, 0.23, 0.98], duration: 0.5 }}
                >
                    <Row d="flex" p="1.5rem" shadow="3" rounded="20px" textColor="dark">
                        <Col size={{ xs: "12", lg: "7" }}>
                            <li>
                                <article className="post-list-item" itemScope itemType="http://schema.org/Article">
                                    <header>
                                        <Div p={{ b: "1rem" }}>
                                            <Text
                                                tag="h2"
                                                textSize={{
                                                    xs: "heading",
                                                    md: "display1",
                                                    lg: "display2",
                                                }}
                                                m={{ b: "0.5rem" }}
                                            >
                                                <Text tag="span" textColor="gunmetal" itemProp="headline">
                                                    {title}
                                                </Text>
                                            </Text>
                                            <Text textSize="tiny">{post?.frontmatter?.date}</Text>
                                            <Tag rounded="circle" bg="accent_darker" textColor="white">
                                                Lo leggi in{" "}
                                                {post?.timeToRead === 1 ? "1 minuto" : post?.timeToRead + " minuti"}
                                            </Tag>
                                            <br />
                                            {
                                                <Tag
                                                    rounded="circle"
                                                    bg="white"
                                                    border="2px solid"
                                                    borderColor="gunmetal"
                                                    textColor="gunmetal"
                                                    m={{ t: "0.5rem" }}
                                                >
                                                    {post.frontmatter.category}
                                                </Tag>
                                            }
                                        </Div>
                                    </header>
                                    <section>
                                        <p
                                            style={{ fontSize: "17px" }}
                                            dangerouslySetInnerHTML={{
                                                __html: post?.frontmatter?.description || post?.excerpt,
                                            }}
                                            itemProp="description"
                                        />
                                    </section>
                                </article>
                            </li>
                        </Col>
                        <Col>
                            {post?.frontmatter?.thumbnail && (
                                <img
                                    style={{ display: "flex", borderRadius: "20px" }}
                                    src={post.frontmatter.thumbnail}
                                />
                            )}
                        </Col>
                    </Row>
                </motion.div>
            </Link>
        </Div>
    )
}

export default PostEntry
