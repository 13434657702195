import PropTypes from "prop-types"
import React, { memo } from "react"
import { Text } from "atomize"
import { theme } from "../settings/config"

SubheaderText.propTypes = {
    children: PropTypes.any,
    textColor: PropTypes.string,
}

SubheaderText.defaultProps = {
    textColor: theme.colors.gunmetal,
}

function SubheaderText({ children, ...others }) {
    return (
        <Text textSize={{ xs: "title", lg: "heading" }} {...others}>
            {children}
        </Text>
    )
}

export default memo(SubheaderText)
